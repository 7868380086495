<template>
  <Panel header="Redirect page">
    <p>
      This is redirect page, please use this link to open in new browser tab
    </p>
    <a :href="linkUrl" target="_blank">{{ linkName }} link</a>
  </Panel>
</template>
<script>
import Panel from 'primevue/panel';
export default {
  props: ['linkUrl', 'linkName'],
  components: { Panel },
};
</script>
